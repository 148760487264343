<template>
  <div class="l-wrapper">
    <q-scroll-area class="l-grow column justify-center">
      <div>
        <div id="myApexChart" ref="myApexChart"></div>
      </div>
    </q-scroll-area>
    <div>
      <q-btn
        unelevated
        class="no-border-radius full-width"
        color="primary"
        label="PENGATURAN GRAFIK"
        @click="showFilter = !showFilter"
      ></q-btn>
    </div>
    <q-dialog v-model="showFilter" position="bottom">
      <q-card style="min-width:300px;">
        <q-card-section class="row justify-between items-center">
          <a class="text-h6 text-bold">Filter:</a>
          <q-btn flat icon="close" v-close-popup></q-btn>
        </q-card-section>
        <q-card-section class="column q-gutter-sm">
          <q-select
            outlined
            class="bg-white"
            label="Year"
            :options="options.optYear"
            v-model="options.selYear"
          ></q-select>
          <q-select
            outlined
            map-options
            emit-value
            :options="options.optType"
            v-model="options.selType"
            class="bg-white"
            label="Summary Type"
          ></q-select>
          <q-select
            outlined
            emit-value
            map-options
            :options="sales.data.sales"
            v-model="options.selSales"
            class="bg-white"
            label="Sales"
            v-if="!(curUser.is_sales == 1)"
          ></q-select>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { useQuasar } from "quasar";
import { onMounted, ref, reactive, watch } from "vue";
import VueApexCharts from "vue3-apexcharts";
import useManager from "./useManager";
import useSales from "../Sales/useSales";

import ApexCharts from "apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  setup() {
    const $q = useQuasar();
    const { getGrowth, data, curUser } = useManager();
    const sales = useSales();

    let chartShow = ref(true);
    let myApexChart = ref(null);
    let myApexChartInstance = ref(null);
    let myApexChartOptions = ref({
      chart: {
        type: "bar",
      },
      series: [
        {
          data: [],
        },
      ],
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function(val) {
          return Intl.NumberFormat("id").format(val);
        },
      },
      xaxis: {
        categories: [],
        show: true,
        labels: {
          formatter: function(val) {
            return Intl.NumberFormat("id").format(val);
          },
        },
      },
    });

    let getOptYear = () => {
      let temp = [];
      for (let item of data.sales_growth) {
        temp.push(item.year);
      }
      options.optYear = temp;
      options.selYear = temp[0];
    };

    let options = reactive({
      optYear: [],
      selYear: null,

      selSales: "semua",

      optType: [
        { label: "Quantity", value: "qty" },
        { label: "Value", value: "value" },
      ],
      selType: "value",
    });

    watch(
      () => options,
      (state, prevState) => {
        updateApex();
      },
      { deep: true }
    );

    let updateApex = () => {
      if (myApexChartOptions.value.series[0].data.length > 0) {
        myApexChartOptions.value.xaxis.categories = [];
        myApexChartOptions.value.series[0].data = [];
      }
      let sales_data = JSON.parse(JSON.stringify(data.sales_growth));
      var months = [
        "N/A",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      sales_data = sales_data.find((val) => {
        return val.year == options.selYear;
      });

      if (sales_data) {
        if (options.selSales == "semua") {
          for (let item of sales_data.month) {
            myApexChartOptions.value.series[0].data.push(item[options.selType]);
            myApexChartOptions.value.xaxis.categories.push(months[item.month]);
          }
        } else {
          for (let item of sales_data.month) {
            let sales = item.sales.find((x) => {
              return x.id_sales == options.selSales;
            });
            if (sales) {
              myApexChartOptions.value.series[0].data.push(
                sales[options.selType]
              );
              myApexChartOptions.value.xaxis.categories.push(
                months[item.month]
              );
            }
          }
        }
      }

      myApexChartInstance.value.updateOptions({});
    };

    onMounted(async () => {
      $q.loading.show({
        message: "Mohon Tunggu...",
      });
      await getGrowth();
      await sales.getOptSales();
      getOptYear();
      if (curUser.value.is_sales == 1) {
        options.selSales = parseInt(curUser.value.id);
      }

      myApexChartInstance.value = new ApexCharts(
        myApexChart.value,
        myApexChartOptions.value
      );
      myApexChartInstance.value.render();

      $q.loading.hide();
    });

    return {
      options,
      data,
      sales,
      curUser,
      chartShow,
      showFilter: ref(false),
      myApexChart,
      myApexChartOptions,
      myApexChartInstance,
    };
  },
};
</script>
